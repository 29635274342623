import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src928099888/src/ConSite/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const FeedSearch = makeShortcode("FeedSearch");
const FeedItems = makeShortcode("FeedItems");
const Tags = makeShortcode("Tags");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img {...{
      "src": "./sub-header.png",
      "alt": "banner"
    }}></img>
    <center> 😶 Welcome </center>
    <br />
    <center>
  <div style={{
        "textAlign": "left",
        "fontSize": "15px"
      }}>
Check out the Dark Mode by clicking the sun, it is pretty cool to me! By the way, I'm a Programmer, I build stuff in Ruby, JS, and sometimes Clojure, checkout my <Link className="gatsby-link" to="/not_ready" mdxType="Link">Projects</Link>.
  </div>
    </center>
    <br />
    <FeedSearch mdxType="FeedSearch" />
    <FeedItems mdxType="FeedItems" />
    <Tags mdxType="Tags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      